$(function () {
	const $form = $(".js-send-form");
	const $formSubmitBtn = $form.find('.js-form-submit');
	const $formBlock = $(".js-form-block");
	const $termsBlock = $(".js-terms-block");
	const $termsAccept = $("#termsAccept");
	const $btnAccept = $(".js-btn-accept");
	const $collaboratorBlock = $(".js-collaborator");
	const $btnAddCollaborator = $(".js-add-collaborator");
	const $attachmentBlock = $(".js-attachments");
	const $btnAddAttachment = $(".js-add-attachment");
	const $application = $("#applicationData");
	const $attachmentsError = $form.find('.js-attachments-error');
	const $captchaError = $form.find('.js-captcha-error');

	let captchaValidateUrl = $form.data('captchaValidateUrl');

	// let siteKey = $form.data('siteKey');
	// if (siteKey == null || !siteKey.length)
	// 	console.error('ReCaptcha siteKey not set');

	let attachmentsMaxSize = $form.attr('max-files-size');
	if (attachmentsMaxSize == null)
		attachmentsMaxSize = 20971520;
	else
		attachmentsMaxSize = Number.parseInt(attachmentsMaxSize, 10);

	$formSubmitBtn.on("click", function () {
		if ($form.valid()) {
			/*grecaptcha.ready(function () {
				grecaptcha.execute(siteKey, {action: 'receptionSend'})
					.then(function (token) {
						$('#reResponseToken').val(token);
						return $form.submit();
					});
			});*/
			$.ajax({
				type: 'GET',
				url: captchaValidateUrl,
				data: {captcha_phrase: $('#captcha_phrase').val()},
				success: function (response) {
					let json = JSON.parse(response);
					showCaptchaError(!json.success);
					if(json.success)
						return $form.submit();
					else {
						let $captchaImage = $('#captcha_image');
						$captchaImage.attr('src', json.captcha.url);
						$captchaImage.attr('height', json.captcha.height);
						$captchaImage.attr('width', json.captcha.width);
					}
				}
			});
		}
		return false;
	});

	$form.validate({
		rules: {applicantEmail: {email: true}},
		submitHandler: function (form, e) {
			// e.preventDefault();
			let success = calculateFilesSize() <= attachmentsMaxSize;
			showAttachmentsSizeError(!success);
			return success;
		}
	});

	function bindRemoveCollaborator() {
		let btns = $(".js-remove-collaborator");
		btns.unbind("click");
		btns.on("click", function () {
			removeCollaborator($(this).closest("fieldset"));
		});
	}

	function bindRemoveAttachment() {
		let btns = $(".js-attachment-remove");
		btns.unbind("click");
		btns.on("click", function () {
			if (attachmentsCount > 1) {
				--attachmentsCount;
				$(this).closest(".js-attachment").remove();
				updateAttachmentsTemplate();
			} else {
				$(this).closest(".js-attachment").find('input[name*="attachment"]').each(function () {
					clearValue(this);
					$(this).parent().find('label').text("Приложите файл");
				});
			}
		});
	}

	function bindAttachmentsLabels() {
		$attachmentBlock.find('.custom-file-input').each(function () {
			$(this).unbind('change');
			$(this).change(onAttachFile);
		})
	}

	function calculateFilesSize() {
		var size = 0;
		var files = $attachmentBlock.find('.custom-file-input');
		files.each(function () {
			var f = this.files != null ? this.files[0] : null;
			size += f != null ? f.size : 0;
		})
		return size;
	}

	$btnAddCollaborator.on("click", function () {
		addCollaborator();
	});
	bindRemoveCollaborator();

	$btnAddAttachment.on("click", function () {
		addAttachment();
	});

	let collaborationCount = 0;
	let attachmentsCount = 1;
	bindRemoveAttachment();
	bindAttachmentsLabels();

	$termsAccept.on("change", function () {
		if ($(this).is(':checked')) {
			$btnAccept.removeAttr("disabled");
		} else {
			$btnAccept.attr("disabled", "disabled");

			$formBlock.addClass("d-none");
			$termsBlock.removeClass("d-none");
		}
	});

	$btnAccept.on("click", function () {
		if ($btnAccept.is(":disabled")) return;
		$(".js-form-block").removeClass("d-none");
		$formBlock.removeClass("d-none");
		$termsBlock.addClass("d-none");
	});

	$form.on("submit", function () {
		var formFields = {
			applicantF: 'applicantF',
			applicantI: 'applicantI',
			applicantO: 'applicantO',
			applicantONot: 'applicantONot',
			applicantEmail: 'applicantEmail',
			applicantPhone: 'applicantPhone',
			applicantEntity: 'applicantEntity',
			text: 'text',
			collaboratorF: 'collaboratorF',
			collaboratorI: 'collaboratorI',
			collaboratorO: 'collaboratorO',
			collaboratorNotO: 'collaboratorNotO',
			recipient: 'recipient',
		};
		var fieldsMappings = {
			applicantF: 'lastName',
			applicantI: 'firstName',
			applicantO: 'middleName',
			applicantONot: 'emptyMiddleName',
			applicantEmail: 'email',
			applicantPhone: 'phone',
			applicantEntity: 'organization',
			text: 'text',
			collaboratorF: 'lastName',
			collaboratorI: 'firstName',
			collaboratorO: 'middleName',
			collaboratorNotO: 'emptyMiddleName',
			recipient: 'recipientId',
		};


		function getCollaboratorIndex(name) {
			function splitAt(str, index) {
				return [str.slice(0, index), str.slice(index)];
			}

			var collaboratorFields = [formFields.collaboratorNotO, formFields.collaboratorF, formFields.collaboratorI, formFields.collaboratorO];
			collaboratorFields.forEach(function (f) {
				var index = name.indexOf(f);
				if (index == 0) {
					var s = splitAt(name, f.length);
					return {field: s[0], index: s[1]};
				}
			});

			return null;
		}

		try {
			let formData = $form.serializeArray();
			var application = {
				applicant: {},
				collaborators: []
			}, temp = {};

			formData.forEach(function (item) {
				var state = getCollaboratorIndex(item.name);
				if (state != null) {
					var collaborator = temp[state.index] || {};
					collaborator[fieldsMappings[state.field]] = (state.field === formFields.collaboratorNotO ? true : item.value);
					temp[state.index] = collaborator;
				} else {
					if (fieldsMappings[item.name] != null) {
						if (formFields.text === item.name || formFields.recipient === item.name) {
							application[fieldsMappings[item.name]] = item.value;
						} else {
							application.applicant[fieldsMappings[item.name]] = item.value;
						}
					}
				}
			});
			Object.keys(temp).forEach(function (k) {
				application.collaborators.push(temp[k]);
			});
			// console.debug(formData);
			// console.debug(application);

			// SET application field value
			$application.val(JSON.stringify(application));

			return true;

		} catch (e) {
			console.error(e);
			return false;
		}
	});


	function removeCollaborator(collaborator) {
		--collaborationCount;
		collaborator.remove();
	}

	function addCollaborator() {
		++collaborationCount;
		let $newcollaborator = $collaboratorBlock.find('.js-collaborator-template').clone();
		var id = "" + new Date().getTime();
		$newcollaborator.find(".form-group").each(function () {
			const $label = $(this).find("label");
			const $input = $(this).find("input");
			$label.attr("for", $label.attr("for") + id);
			$input.attr("id", $input.attr("id") + id);
			$input.attr("name", $input.attr("name") + id);
			$input.removeAttr("disabled");
		});

		$newcollaborator.removeClass("js-collaborator-template");
		$newcollaborator.removeClass("d-none");
		$newcollaborator.insertBefore($btnAddCollaborator);
		bindRemoveCollaborator();
	}

	function addAttachment() {
		++attachmentsCount;
		let $newAttachment = $attachmentBlock.find('.js-attachment-template').clone();
		$newAttachment.removeClass("js-attachment-template");
		$newAttachment.find('.js-attachment-remove').removeClass("d-none");
		$newAttachment.find('label').text("Приложите файл");
		$newAttachment.find('.custom-file-input').each(function () {
			clearValue(this);
			this.files = null;
		});

		$attachmentBlock.append($newAttachment);
		bindRemoveAttachment();
		bindAttachmentsLabels();
		updateAttachmentsTemplate();
	}

	function showCaptchaError(show) {
		if (show) {
			$captchaError.text('Неверно введён проверочный код');
			$captchaError.removeClass('d-none');
		} else {
			$captchaError.text('');
			$captchaError.addClass('d-none');
		}
	}

	function showAttachmentsSizeError(show) {
		if (show) {
			$attachmentsError.text('Размер приложенных файлов не должен превышать ' + getDisplaySize(attachmentsMaxSize));
			$attachmentsError.removeClass('d-none');
		} else {
			$attachmentsError.text('');
			$attachmentsError.addClass('d-none');
		}
	}

	function clearValue(input) {
		$(input).val('');
		input.value = '';
		if (input.value) {
			input.type = "text";
			input.type = "file";
		}
	}

	function updateAttachmentsTemplate() {
		showAttachmentsSizeError(false);
		$attachmentBlock.find('.js-attachment').each(function (i) {
			$(this).removeClass('js-attachment-template');
			if (i == 0)
				$(this).addClass('js-attachment-template');
		});

	}

	function onAttachFile() {
		showAttachmentsSizeError(false);
		var label = $(this).next('label');
		var files = this.files;
		if (files != null)
			$(label).text(getFileName(files[0], true));
	}

	function getFileName(file, withSize) {
		return file.name + (withSize ? (' (' + getDisplaySize(file.size) + ')') : '');
	}

	function getDisplaySize(size) {
		var fSExt = new Array('Байт', 'КБ', 'МБ', 'ГБ'), i = 0;
		while (size > 900) {
			size /= 1024;
			i++;
		}
		var exactSize = (Math.round(size * 100) / 100) + ' ' + fSExt[i];
		console.debug('FILE SIZE = ', exactSize);
		return exactSize;
	}
})