//
// _blindConfig_ object defined in blind panel tag
//
var $container = initContainer();

jQuery(function () {
	initContainer();

	jQuery('.js-blind-controls').on('click', function (e) {
		initContainer();
		e.preventDefault();
		var $this = jQuery(this),
			mode = $this.data('mode'),
			value = $this.data('value');

		if (mode && mode !== 'enable') {
			$this.siblings('.active').removeClass('active');
			$this.toggleClass('active');
		}
		blindMode(mode, value);
	});

	isBlindEnable() && initBlind();
});

function initContainer() {
	if ($container == null)
		$container = typeof _blindConfig_ !== 'undefined' ? jQuery('#' + _blindConfig_.containerId) : null;

	return $container
}

var blindMode = function (mode, value) {
	var blindPanelHiddenCss = _blindConfig_.hiddenClass;
	if (_blindConfig_.logsEnabled)
		console.log(mode, value);

	if (isDisableBlindMode(mode, value)) {
		$container.removeClass('page_state_blind');
		jQuery('.blind-mode').addClass(blindPanelHiddenCss);
		jQuery('.blind-mode-link').removeClass(blindPanelHiddenCss);

		set_cookie(_blindConfig_.cookies.mode, 'false', '/', 7);
//            delete_cookie("pvt_cecutient"); не работает...

		jQuery('html').css('font-size', '');
		toggleImgAlt();

	} else if (isEnableBlindMode(mode, value)) {
		$container.addClass('page_state_blind').addClass(_blindConfig_.values.schemaClass);
		jQuery('.blind-mode').removeClass(blindPanelHiddenCss);
		jQuery('.blind-mode-link').addClass(blindPanelHiddenCss);
		set_cookie(_blindConfig_.cookies.mode, 'true', '/', 7);

		initBlind();
	} else {
		updatePreviewClass(mode, value);
		$container.data(mode, value);
		set_cookie(_blindConfig_.cookies.getModeCookieName(mode), value, '/', 7);

		_blindConfig_.values[mode] = value;

		if (mode === 'size')
			setHtmlSize(value);

		if (mode === 'images')
			toggleImgAlt(!value);
	}
};
function isBlindEnable() {
	return $container.hasClass('page_state_blind');
}
function getPreviewClass(mode, value) {
	return 'page_viewtype_' + mode + '-' + value;
}

function updatePreviewClass(mode, value) {
	$container.removeClass(getPreviewClass(mode, $container.data(mode)));
	$container.addClass(getPreviewClass(mode, value));
}

function initBlind() {
	var size = _blindConfig_.values.size;
	if (size)
		setHtmlSize(size);

	toggleImgAlt(_blindConfig_.values.images === 'false');
}

function isEnableBlindMode(mode, value) {
	return mode === 'enable' && value === true;
}
function isDisableBlindMode(mode, value) {
	return mode === 'enable' && value === false;
}
function get_cookie(cookie_name) {
	var results = document.cookie.match('(^|;) ?' + cookie_name + '=([^;]*)(;|$)');
	if (results)
		return ( decodeURIComponent(results[2]) );
	else
		return null;
}

function delete_cookie(cookie_name) {
	var cookie_date = new Date();  // Текущая дата и время
	cookie_date.setTime(cookie_date.getTime() - 365 * 24 * 60 * 60 * 1000);
	document.cookie = cookie_name + '=; expires=' + cookie_date.toGMTString();
}

function set_cookie(name, value, path, expiresDays) {
	/*domain, secure*/
	var cookie_string = name + '=' + encodeURIComponent(value);
	if (expiresDays) {
		var cookie_date = new Date();  // Текущая дата и время
		cookie_date.setTime(cookie_date.getTime() + expiresDays * 24 * 60 * 60 * 1000);
		cookie_string += '; expires=' + cookie_date.toGMTString();
	}
	if (path)
		cookie_string += '; path=' + path;
//            cookie_string += "; path=" + encodeURIComponent(path);
//        if (domain)
//            cookie_string += "; domain=" + encodeURIComponent(domain);
//        if (secure)
//            cookie_string += "; secure";
	document.cookie = cookie_string;
}
function setHtmlSize(value) {
	jQuery('html').css('font-size', jQuery('.' + getPreviewClass('size', value)).css('font-size'));
}

function toggleImgAlt(showAlt) {
	var $imgs = jQuery('.visual img'),
		$oldImgs = jQuery('.body img');
	if (showAlt) {
		$imgs.each(function () {
			var $visual = jQuery(this).closest('div.visual');
			if ($visual.find('.js-alt').length === 0) {
				$visual.prepend("<i class='js-alt'>Изображение: " + jQuery(this).attr('alt') + "</i>");
			}
		});
		$oldImgs.each(function () {
			var $this = jQuery(this);
			if ($this.closest(".js-img-wrap").length === 0) {
				$this.wrap('<span class="js-img-wrap"></span>');
				$this.closest('.js-img-wrap').css({
					'width': $this.width(),
					'display': 'inline-block'
				});
			}
			$this.before("<i class='js-alt'>Изображение: " + $this.attr('alt') + "</i>");
		});
	} else {
		$imgs.each(function () {
			jQuery(this).closest('div.visual').find('.js-alt').remove();
		});
		$oldImgs.each(function () {
			jQuery(this).closest('.js-img-wrap').find('.js-alt').remove();
		});
	}
}