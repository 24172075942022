$(function () {
	var $onlinereceptionForm = $('#onlinereceptionform');

	$onlinereceptionForm.validate({
		errorClass: "error",
		focusInvalid: false,
		onfocusout: false,
		rules: {fio: "required", email_tel: "required", skype: "required"}
	});

	$('#onlinereceptionsubmit').click(function () {
		if ($onlinereceptionForm.valid()) {
			grecaptcha.ready(function () {
				var siteKey = $onlinereceptionForm.data('siteKey');

				grecaptcha.execute(siteKey, {action: 'onlineReceptionSend'})
					.then(function (token) {
						$('#reResponseToken').val(token);
						return $onlinereceptionForm.submit();
					});
			});
		}
		return false;
	});

})