require('jquery-validation');
require('jquery-validation/dist/localization/messages_ru');

var m_comments_lists = [];
jQuery(document).ready(function () {
    initComments();
    $('#commentEditModal').appendTo($('body'));

    $('#filterCommentsBtnReset').click(function (e) {
        $(".filters input,.filters select").val("");
        // setCommentsFormAdminAction('filter');
        $('#filterCommentsBtn').trigger("click")
    });
    $('.filters .datepicker').datepicker({format: 'dd.mm.yyyy', language: 'ru'});
});

function initComments() {
    var $listElements = jQuery('.type-dynamic-comments');
    console.info(".type-dynamic-comments elements found: " + $listElements.length);
    if ($listElements.length > 0) {
        $listElements.each(function () {
            // initialize lists with values from data attributes
            var $list = jQuery(this);
            if (typeof $list.data("list") !== 'undefined') {
                // read list data
                var list = $list.data("list");
                // add more data to list
                list.$element = $list;
                list.$commentForm = $list.find('.comment-form');
                list.$commentAddLink = $list.find('.js-comments-add');
                list.id = $list.attr("id");
                list.elementId = $list.data("id");
                list.$entries = $list.find(".comments-list-wrapper");
                list.$spinner = $list.find(".list-spinner");
                m_comments_lists[list.id] = list;
                // initial load comments list
                loadComments(list.id);
            }
            initCommentsForm(list.id);
        });
    }
}

function loadThumbs(listId) {
    var list = m_comments_lists[listId];
    if (isThumbsEnabled(list)) {
        jQuery.get(buildThumbsLink(list, 'action=userthumbs'), function (thumbsJson) {
            list.$userThumbs = thumbsJson.thumbs;
            initThumbs(listId);
        }, "json");
    }
}

function initThumbLink(list, linkElement) {
    var userThumb = list.$userThumbs[$(linkElement).data('comment')],
        thumbsWrapper = $(linkElement).parent(),
        activeClass = 'active';
    if (typeof userThumb !== 'undefined') {
        if ($(linkElement).data('thumb') === userThumb) {
            thumbsWrapper.data('active', $(linkElement).data('type'));
            $(linkElement).data('thumb', -1);
            $(linkElement).addClass(activeClass);
        } else {
            $(linkElement).data('thumb', $(linkElement).data('type') === 'up' ? 1 : 0);
            $(linkElement).removeClass(activeClass);
        }
    } else {
        $(linkElement).data('thumb', $(linkElement).data('type') === 'up' ? 1 : 0);
        thumbsWrapper.removeData('active');
        $(linkElement).removeClass(activeClass);
    }
}

function updateThumbCounters(thumbsWrapperEl) {
    $(thumbsWrapperEl).find('.js-thumb-link').each(function () {
        $(this).find('.comments__thumbs-count')
            .text($(this).data('type') === 'up' ? thumbsWrapperEl.data('up') : thumbsWrapperEl.data('down'));
    })
}

function initThumbs(listId) {
    var list = m_comments_lists[listId];
    $(list.$element).find('.js-thumb-link')
        .each(function () {
            initThumbLink(list, $(this));
        })
        .click(function () {
            var $link = $(this),
                $thumbsWrapper = $link.parent(),
                currentActive = $thumbsWrapper.data('active'),
                commentId = $(this).data('comment'),
                thumb = $(this).data('thumb'),
                clickType = $(this).data('type'),
                params = 'action=thumb&thumb=' + thumb + '&comment=' + commentId;
            jQuery.get(buildThumbsLink(list, params), function (jsonRes) {
                if (jsonRes.status === true) {
                    list.$userThumbs[commentId] = thumb === -1 ? undefined : thumb;
                    $thumbsWrapper.data(clickType, Number($thumbsWrapper.data(clickType)) + jsonRes.result);
                    if (typeof currentActive !== 'undefined' && currentActive !== clickType) {
                        if (clickType === 'up') {
                            if (currentActive === 'down') {
                                $thumbsWrapper.data('down', Number($thumbsWrapper.data('down')) - jsonRes.result);
                            }
                        } else {
                            if (currentActive === 'up') {
                                $thumbsWrapper.data('up', Number($thumbsWrapper.data('up')) - jsonRes.result);
                            }
                        }
                    }
                    updateThumbCounters($thumbsWrapper);
                    $thumbsWrapper.find('.js-thumb-link').each(function () {
                        initThumbLink(list, $(this));
                    });
                }
            }, "json");
        });
}

function loadComments(listId, ajaxOptions) {
    var list = m_comments_lists[listId];
    // move answer form out of the list
    extractAnswerFormFromList(list);
    // calculate the spinner position in context to the visible list part
    var scrollTop = jQuery(window).scrollTop();
    var windowHeight = jQuery(window).height();
    var elementTop = list.$element.offset().top;
    var elementHeight = list.$element.outerHeight(true);
    var visibleHeight = Math.min(scrollTop + windowHeight, elementTop + elementHeight) - Math.max(scrollTop, elementTop);
    var invisibleHeight = elementHeight - visibleHeight;
    var spinnerPos = ((0.5 * visibleHeight) + invisibleHeight) / elementHeight * 100.0;

    // show the spinner
    if (visibleHeight > 0) {
        list.$spinner.css("top", spinnerPos + "%").fadeIn(100);
    }

    jQuery.get(buildCommentsLink(list, ajaxOptions), function (ajaxHtml) {
        var $result = jQuery(ajaxHtml);
        var resultData = $result.find('#resultdata').first().data('result');
        var $listError = $result.find('.comments-list-error');
        if (typeof resultData !== 'undefined') {
            // update comments header infos
            updateCommentsHeader(list, resultData);
            list.$currentPage = resultData.currentPage;
            var $newEntries = $result.find(".comments-list");
            // set min-height of list to avoid screen flicker
            list.$entries.css("min-height", list.$entries.height() + 'px');
            // remove the old entries when list is reloaded
            list.$entries.empty();
            // add the new elements to the list
            $newEntries.appendTo(list.$entries);
            // reset the min-height of the list now that the elements are visible
            list.$entries.animate({'min-height': "0px"}, 500);
            // fade out the spinner
            list.$spinner.fadeOut(250);

            if (isThumbsEnabled(list)) {
                loadThumbs(listId);
            }
            initClickEvents(listId);
        } else if ($listError.length) {
            list.$element.empty();
            $listError.appendTo(list.$element);
        } else {
            list.$spinner.fadeOut(250);
        }
    }, "html");
}

function updateCommentsHeader(list, info) {
    $(list.$element).find('.comments-count').text('(' + info.count + ')');
}

function isThumbsEnabled(list) {
    return typeof list.ajaxThumbs !== 'undefined' && list.ajaxThumbs.length;
}

function buildThumbsLink(list, ajaxOptions) {
    var extraParams = typeof ajaxOptions !== 'undefined' ? ajaxOptions : '';
    return list.ajaxThumbs + (list.ajaxThumbs.indexOf('?') >= 0 ? '&' : '?') + extraParams;
}

function buildCommentsLink(list, ajaxOptions) {
    var extraParams = typeof ajaxOptions !== 'undefined' ? ajaxOptions : '';
    return list.ajax + (list.ajax.indexOf('?') >= 0 ? '&' : '?') + extraParams;
}

function initFormValidating(listId, $form) {
    $form.validate({
        rules: {email: {email: true}},
        submitHandler: function ($form, e) {
            if (typeof e !== 'undefined') {
                e.preventDefault();
            }
            sendComment(listId, $form);
        }
    });
}

function initCommentsForm(listId) {
    var list = m_comments_lists[listId];
    initFormValidating(listId, list.$commentForm);
    list.$commentAddLink.on("click", function (e) {
        toggleForm(list.$commentForm, $(this));
    });
    list.$commentForm.find('[name="attachment"]').on('change', function () {
        getFileName.call(this);
    });
}

function toggleForm(form, toggleElement) {
    if (toggleElement.attr("has_form") === "has_form") {
        toggleElement.removeAttr("has_form");
        // toggleElement.text(showText);
        form.slideUp(200);
    } else {
        toggleElement.attr("has_form", "has_form");
        // toggleElement.text(hideText);
        form.slideDown(200);
    }
}

function fillCommentsEditForm($form, comment) {
    $form.find("[name=comment_id]").val(comment.id);
    $form.find("[name=user_name]").val(comment.authorName);
    $form.find("[name=email]").val(comment.authorEmail);
    $form.find("[name=content]").val(comment.content);
    $form.find("[name=comment_type]").val(comment.type);
    $form.find("[name=comment_flag]").val(comment.flags);
}

function getFileName() {
    var self = this;
    $(self).closest('.input-file').find('[name="file"]').val(this.files.length ? self.files[0].name : '');
}

function initClickEvents(listId) {
    var list = m_comments_lists[listId];
    $(list.$element).find('.js-edit-link').click(function () {
        var $this = $(this),
            $editForm = $('#commentEditModal').find('.comment-edit-form'),
            commentId = $this.data('comment');
        if ($editForm) {
            clearCommentFields($editForm);
            loadComment(listId, commentId, function (comment) {
                fillCommentsEditForm($editForm, comment);
                initFormValidating(listId, $editForm);
            }, function (error) {
                showMessage($this, error, 'danger');
            });
        } else {
            console.error('Comment edit form not found');
            showMessage($this, 'Редактирование недоступно', 'warning');
        }
        // if ($editForm) {
        //     clearCommentFields($editForm);
        //     fillCommentsEditForm($editForm, $this.parents('.js-comments-item'));
        //     initFormValidating(listId, $editForm);
        // } else {
        //     console.error('Comment edit form not found');
        // }
    });

    if (existsAnswerForm(list)) {
        $(list.$element).find('.js-answer-link').click(function () {
            var $this = $(this), $answerForm = getListAnswerForm(list);

            $this.siblings(".js-answer-form").html($answerForm);
            $answerForm.find("[name=parent_comment]").val($this.data("comment"));

            if ($this.attr("has_form") === "has_form") {
                $this.removeAttr("has_form");
                $answerForm.slideUp(200);
            } else {
                $(list.$element).find('.js-answer-link').each(function () {
                    $(this).removeAttr("has_form");
                });
                $this.attr("has_form", "has_form");
                $answerForm.slideDown(200);
            }
            $answerForm.find('[name="attachment"]').on('change', function () {
                getFileName.call(this);
            });
            initFormValidating(listId, $answerForm);
        });
    } else {
        $(list.$element).find('.js-answer-link').hide();
    }

    $(list.$element).find('.js-action-link').click(function () {
        var $this = $(this), action = $this.data("action");
        if (action === "delete") {
            if (confirm("Вы действительно хотите удалить комментарий?")) {
                adminComment(listId, $this);
            }
        } else {
            adminComment(listId, $this);
        }
    });

    $(list.$element).find('.list-pagination .page-link').click(function () {
        if (typeof $(this).data('page') !== 'undefined') {
            loadComments(listId, getListPageParam($(this).data('page')));
        }
    });
}

function getListPageParam(page) {
    return typeof page !== 'undefined' ? 'filter_page=' + page : '';
}

function getListAnswerForm(list) {
    return $(list.$element).find('.comment-answer-form');
}

function existsAnswerForm(list) {
    return getListAnswerForm(list).length > 0;
}

function isAnswerForm(form) {
    return form.hasClass('comment-answer-form');
}

function isEditForm(form) {
    return form.hasClass('comment-edit-form');
}

function resetForm(listId, form) {
    var list = m_comments_lists[listId];
    if (isEditForm(form)) {
        $(form).closest('.modal').modal('hide');
    } else if (isAnswerForm(form)) {
        extractAnswerFormFromList(list, form);
        $(list.$element).find('.js-answer-link').each(function () {
            $(this).removeAttr("has_form");
        });
    } else {
        toggleForm(list.$commentForm, list.$commentAddLink);
    }
    clearCommentFields(form);
}

function extractAnswerFormFromList(list, form) {
    var $answerForm = form;
    if (typeof $answerForm === 'undefined') {
        $answerForm = $(list.$element).find('.comment-answer-form');
    }
    $($answerForm).hide();
    list.$element.append($answerForm);
}

function loadComment(listId, commentId, handleComment, handleError) {
    var list = m_comments_lists[listId];
    $.get(list.actionsLink, {
        comment_resource: list.resource,
        comment_config_resource: list.config,
        comment_id: commentId,
        comment_action: 'load'
    }, function (jsonResult) {
        if (jsonResult.result === 'ok') {
            handleComment(jsonResult.comment)
        } else {
            handleError(jsonResult.message);
        }
    }, 'json');
}

function sendComment(listId, form) {
    var list = m_comments_lists[listId];
    var commentsForm = $(form);
    var formData = new FormData(form);
    $.ajax({
        type: 'POST',
        url: list.actionsLink,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        // data: commentsForm.serialize(),
        success: function (result) {
            refreshCaptcha(listId);
            var jsonResult = JSON.parse(result);
            if (jsonResult.result === 'ok') {
                if (jsonResult.reload === true) {
                    loadComments(listId, isAnswerForm(commentsForm) ? getListPageParam(m_comments_lists[listId].$currentPage) : '');
                } else {
                    showSendResult(listId, commentsForm, true, jsonResult.message);
                }
                resetForm(listId, commentsForm); // !!! call after show result because message shown after form
            } else if (jsonResult.result === 'error-captcha') {
                commentsForm.find('input[name=captcha_phrase]').addClass('error');
            } else {
                showSendResult(listId, commentsForm, false, jsonResult.message);
            }
        }
    });
}

function showSendResult(listId, form, success, message) {
    // remove old messages
    var list = m_comments_lists[listId];
    $(list.$element).find('.js-action-result').remove();
    var msgHtml = $('<div class="js-action-result text-' + (success ? 'success' : 'danger') + '">' + message + '</div>');
    msgHtml.insertAfter(form);
    setTimeout(function () {
        msgHtml.remove();
    }, 5000);
}

function refreshCaptcha(listId) {
    m_comments_lists[listId].$element.find('.js-captcha-img')
        .attr("src", "/system/modules/ru.zen_it.portal.content.comments/elements/captcha.jsp?" + new Date().getTime());
}

function clearCommentFields(form) {
    $(form).find("input[type=text], input[type=file], textarea").each(function () {
        $(this).val('');
    });
}

function adminComment(listId, aObject) {
    var action = aObject.data("action"),
        comment_id = aObject.data("comment"),
        list = m_comments_lists[listId];
    // console.log("adminComment", action, comment_id);li
    $.ajax({
        type: 'POST',
        url: list.actionsLink,
        data: {comment_action: action, comment_id: comment_id, comment_config_resource: list.config},
        success: function (result) {
            var jsonResult = JSON.parse(result);
            doAction(listId, action, comment_id, jsonResult.result);
        }
    });
}

function showMessage(aElement, message, type) {
    var $messageDiv = $(aElement).closest('.js-action-bar').find('.js-action-result');
    $messageDiv.html('<small class="text-' + type + '">' + message + '</small>');
    setTimeout(function () {
        $messageDiv.html('');
    }, 3000);
}

function doAction(listId, action, comment, result) {
    var $aElement = $(".js-action-link[data-action=" + action + "][data-comment=" + comment + "]");
    var list = m_comments_lists[listId];
    if (result === "ok") {
        // reload comments on success action
        loadComments(listId, getListPageParam(list.$currentPage))
    } else {
        if (action === "approve") {
            showMessage($aElement, "Ошибка подтверждения комментария", "danger");
        } else if (action === "block") {
            showMessage($aElement, "Ошибка при блокировании комментария", "danger");
        } else if (action === "unblock") {
            showMessage($aElement, "Ошибка при разблокировании комментария", "danger");
        } else if (action === "delete") {
            showMessage($aElement, "Ошибка удаления комментария", "danger");
        }
    }
}